// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
// import Viewer from 'v-viewer';
// import VuejsClipper from 'vuejs-clipper';
// import VueGtm from '@gtm-support/vue2-gtm';

import App from './App.vue';
// import store from './store';
import router from './router';
// import './registerServiceWorker';
// import './plugins/element';
// import defineAxiosDefaults from './utils/axios-setup';

// defineAxiosDefaults();

// library.add(faApple, faAndroid, faUser);

// Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue.config.productionTip = false;
//
// Vue.use(VueGtm, {
//   id: 'GTM-TGWS8NX',
//   enabled: process.env.NODE_ENV === 'production', // only enabled for production
//   vueRouter: router,
// });
// Vue.use(VuejsClipper);
// Vue.use(Viewer);

new Vue({
  router,
  // store,
  render: h => h(App),
}).$mount('#app');
